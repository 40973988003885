$magenta: #f11f8c;
$magenta2: #c21572;
$dark-magenta: #430b2a;
$color-1: #0fd1a2;




$font-ultra: tele-groteskult, Arial, sans-serif;
$font-fet: tele-groteskfet, Arial, sans-serif;
$font-halb: tele-groteskhal, Arial, sans-serif;
$font-norm: tele-grotesknor, Arial, sans-serif;
