@import './variable.scss';

@font-face {
    font-family: 'tele-groteskfet';
    src: url('/fonts/tele-groteskfet.woff2') format('woff2'),
         url('/fonts/tele-groteskfet.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tele-groteskhal';
    src: url('/fonts/tele-groteskhal.woff2') format('woff2'),
         url('/fonts/tele-groteskhal.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tele-grotesknor';
    src: url('/fonts/tele-grotesknor.woff2') format('woff2'),
         url('/fonts/tele-grotesknor.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tele-groteskult';
    src: url('/fonts/tele-grotesk-ult.woff2') format('woff2'),
         url('/fonts/tele-grotesk-ult.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


body,
html,
section,
main,
header,
footer,
div,p,h1,h2,h3,h4,h5 {
    margin: 0;
    padding: 0;
}

html,
body {
    font-family: $font-halb;

    box-sizing: border-box;

    background: black;
    width: 100%;
    min-height: 100vh;

    font-size: 20px;

    @media (max-width: 1600px) {
        font-size: 18px;
    }

    @media (max-width: 1440px) {
        font-size: 16px;
    }

    @media (max-width: 450px) {
        font-size: 12px;
    }

    min-width: 425px;
}

#root {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
}


.page-header {
    background: white;
    height: 86px;

    .page-header-wrapper {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        max-width: 1484px;
        width: 95%;
    }


    nav {
        button,
        a {
            padding: 0 0 0 2em;
            font-family: $font-ultra;
            text-transform: uppercase;
            font-size: .75rem;
            border: none;
            background: none;

            text-decoration: none;
            color: black;

            &:hover {
                color: $magenta;
            }
        }
    }

    a.t-mobile {
        background: url('/images/logos/t-mobile.png') 50% 50% no-repeat;
        background-size: contain;
        display: block;

        width: 84px;
        height: 42px;

        text-indent: -9999px;
    }
}

.page {
    flex: 1 1 100%;
}

.loader {
    width: 100%;

    background: white;
    display: block;

    &:before {
        content: '';
        height: 300px;
        width: 100%;
        display: block;
        background: url('/images/loader.gif') 50% 50% no-repeat;
    }
}

.page-wrapper {
    max-width: 1608px;
    width: 100%;

    margin: 0 auto;
}

.white-box {
    background: white;
    padding: 100px;
}

.counter {

    border: 1px solid $magenta2;

    padding: 1em 2em .5em;
    border-radius: 1em;

    .text {
        color: $magenta2;
        text-transform: uppercase;
        font-family: $font-norm;

        font-size: 1.2rem;
    }

    .value {
        color: white;
        text-transform: uppercase;
        font-family: $font-norm;

        font-size: 3.6rem;
        letter-spacing: .1em;

        text-align: center;
    }
}
